<template>
  <div class="GalleryVisorContent">
    <div class="GalleryList">
      <DynamicScroller class="scroller" :items="items" v-bind="scroller">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.file.src]" :data-index="index">
            <GalleryImage :item="item" :key="item._id" :index="index" @loaded="loadedFirst = true" />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <div class="GalleryListMore" v-if="loadedFirst && !allLoaded">
        <CircleButton icon="plus" :label="$locale['see_more']" size="42px" @click="nextItems" />
      </div>
    </div>
  </div>
</template>

<script>
import GalleryVisor from "./GalleryVisor.js";
export default {
  mixins: [GalleryVisor],
  props: ["item"],
  components: {
    GalleryCategories: () => import("./GalleryCategories.vue"),
    GalleryImage: () => import("./GalleryImage.vue"),
  },
  data: function() {
    return {
      loadedFirst: false,
      allLoaded: false,
      scroller: {
        buffer: 1080,
        "min-item-size": 320,
        "key-field": "_id",
        "page-mode": true,
      },
    };
  },
};
</script>
