const GalleryVisor = Go.vue;

export default GalleryVisor;

GalleryVisor.data = function() {
  return {
    items: [],
  };
};

GalleryVisor.methods.init = function() {
  this.items.push(this.item);
};

GalleryVisor.methods.nextItems = function() {
  this.dataItems = Go.store("gallery");
  if (this.dataItems) {
    this.galleryitems = this.dataItems.data.items;
  }

  if (this.galleryitems) {
    this.galleryitems.forEach((element) => {
      element.items.forEach((item) => {
        String(item._id) !== String(this.item._id) && this.items.push(item);
      });
    });
  }

  this.allLoaded = true;
};

GalleryVisor.beforeMount = function() {
  this.init();
};
